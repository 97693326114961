<template>
  <div class="adjust-iframe">
    <iframe :src="src" :id="id" :width="width" :height="adjustHeight" frame-border="0" scrolling="no" margin-width="0" ref="adjustIframe"></iframe>
  </div>
</template>

<script>
export default {
  name: 'AdjustIframe',
  props: {
    id: String,
    src: {
      type: String,
    },
    width: {
      type: String | Number,
      default: '100%'
    },
    height: {
      type: String | Number,
      default: 0
    }

  },
  data() {
    return {
      adjustHeight: 0
    }
  },
  mounted() {
    console.log('iframe', [this])
    this.adjustHeight = this.height
    if (this.height != 0) return;
    this.interval = setInterval(() => {
      this.adjustIframe();
    }, 500);
    this.adjustIframe();
    window.addEventListener('resize', this.adjustIframe)
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
    window.removeEventListener('resize', this.adjustIframe)
  },
  methods: {
    adjustIframe() {
      try {
        let iframe = this.$refs.adjustIframe;
        let innerDocument =
          iframe.contentWindow.document || iframe.contentDocument.parentWindow;
        if (!innerDocument) return;
        let bHeight = innerDocument.body.scrollHeight;
        // let dHeight = innerDocument.documentElement.scrollHeight;
        // let maxHeight = Math.max(bHeight, dHeight);
        this.adjustHeight = bHeight;
        // console.log(this.adjustHeight, dHeight, bHeight)
      } catch (e) {
        console.log
        return
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.adjust-iframe iframe {
  border: none !important;
  overflow: hidden;
  min-height: 0;
}
</style>