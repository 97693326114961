<template>
  <div class="test">
    <h1>测试组件打包</h1>
  </div>
</template>

<script>
export default {
  name: 'Test'
}
</script>

<style lang="scss" scoped>
  .test {
    h1 {
      color: palevioletred;
    }
  }
</style>