<template>
  <div class="search-layout-item" :style="{width}" v-show="!hidden">
    <div class="search-layout-item-label" :style="{width: labelWidth}" v-if="label && label.length > 0" :suffix="suffix">{{label}}</div>
    <div class="search-layout-item-value">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchItem',
  props: {
    label: {
      type: String,
    },
    width: {
      type: String,
      default: '25%',
    },
    suffix: {
      type: String,
      default: ':',
    },
    labelWidth: {
      type: String,
      default: '120px',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.search-layout-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 10px;
  &-label {
    color: #314659;
    flex: 0 0 auto;
    text-align: right;
    &::after {
      content: attr(suffix);
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
  &-value {
    flex: 1 1;
  }
}
</style>