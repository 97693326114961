<template>
  <div class="item" :class="{'label-top': labelPosition == 'top'}" :style="{width}">
    <div class="item-label" :suffix="suffix" :style="{width: labelWidth}">{{label}}</div>
    <div class="item-value">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Item',
  props: {
    width: {
      type: String,
      default: '25%',
    },
    labelWidth: {
      type: String,
      default: '120px',
    },
    labelPosition: {
      type: String,
      default: 'left', // top
    },
    suffix: {
      type: String,
      default: ':',
    },
    label: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  padding: 6px 10px 6px 0;
  &-label {
    color: #314659;
    flex: 0 0 auto;
    text-align: right;
    &::after {
      content: attr(suffix);
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
  &.label-top {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    .item-label {
      margin-bottom: 3px;
      text-align: left;
    }
  }
  &-value {
    flex: 1 1;
  }
}
</style>