import Tabs from './src/main.vue'
import TabPane from './src/tab-pane.vue'

Tabs.install = (Vue, { prefix = '' } = {}) => {
  Vue.component(prefix + Tabs.name, Tabs)
  Vue.component(prefix + TabPane.name, TabPane)
}
Tabs.TabPane = TabPane

export default Tabs
