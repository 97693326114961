<template>
  <div class="nav">
    <div class="nav-left">
      <div class="nav-left-btn-back" :class="{ 'is-backing': isBacking }" @click="handleOnBack">
        <i class="iconfont icon-back" aria-hidden="true"></i>
        <span>{{backText}}</span>
      </div>
    </div>
    <div class="nav-title">{{title}}</div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    backText: {
      type: String,
      default: '返回'
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
       isBacking: false
    }
  },
  watch: {
    isBacking() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
      this.isBacking = false
    }, 5000);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.timer = null
  },
  methods: {
    handleOnBack() {
      this.isBacking = true;
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  height: 48px;
  background: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  &-left {
    &-btn-back {
      font-weight: 600;
      color: #0d1a26;
      font-size: 16px;
      cursor: pointer;
      padding: 0 10px 0 15px;
      &:hover {
        color: rgba(19, 104, 231, 0.8);;
        i {
          color: rgba(19, 104, 231, 0.8);;
        }
      }
      i {
        font-size: 16px;
        color: #0d1a26;
        padding: 0 3px;
        font-weight: 400;
        display: inline-block;
      }
      &.is-backing {
        color: #ADB5BD;
        user-select: none;
        cursor: not-allowed;
        i {
          color: #ADB5BD;
          animation: backAnimation 1.5s infinite;
        }
      }
    }
  }
  &-title {
    font-weight: 600;
    color: #0d1a26;
    margin-left: 5px;
    font-size: 16px;
  }
}

@keyframes backAnimation {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-10px, 0);
    opacity: 0;
  }
}
</style>