<template>
  <div :class="'tooltip' + '-' + position" :data-tooltip="tip">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    tip: String,
    position: {
      type: String,
      default: 'bottom'
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('./tooltip.scss');
</style>