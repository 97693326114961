import Test from '../packages/test'
import Loader from '../packages/loader'
import SearchLayout from '../packages/search-layout'
import TableLayout from '../packages/table-layout'
import AdjustIframe from '../packages/iframe'
import Navigation from '../packages/navigation'
import Checkbox from '../packages/checkbox'
import Transfer from '../packages/transfer'
// import ConfigPage from '../packages/config-page'
import Button from '../packages/button'
import Group from '../packages/group'
import Tabs from '../packages/tabs'
import Message from '../packages/message'
import Notification from '../packages/notification'
import Draggable from 'vuedraggable'
import Upload from '../packages/upload'
import Tooltip from '../packages/tooltip'
import '../packages/tooltip/src/tooltip.scss'
// console.log(EventBus, '&&&&')

const components = [
  Test,
  Loader,
  SearchLayout,
  TableLayout,
  AdjustIframe,
  Navigation,
  Checkbox,
  Transfer,
  // ConfigPage,
  Button,
  Group,
  Tabs,
  Message,
  Notification,
  Tooltip,
  Upload
]

const install = (Vue, opts = {}) => {
  components.forEach(component => {
    Vue.use(component, opts)
  })
  Vue.component('HvDraggable', Draggable)
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue, { prefix: 'Hv'})
}

export default {
  install,
  Test,
  Loader,
  SearchLayout,
  TableLayout
}
