<template>
  <div class="search-layout">
    <div class="search-layout-title" v-if="title && title.length > 0">{{title}}</div>
    <div class="search-layout-content">
      <slot :hidden="collapsed"></slot>
      <!-- <template v-if="!collapsed">
      <slot name="wrapped">
      </slot>
    </template> -->
      <search-layout-item class="btn-group">
        <slot name="buttons"></slot>
        <div class="btn-collapsed" :class="{opened: !collapsed}" @click="collapsed = !collapsed" v-if="!$slots.default">
          <a>{{ collapsed ? '展开' : '收起'}}</a>
          <i class="iconfont icon-down" aria-hidden="true"></i>
        </div>
      </search-layout-item>
    </div>
  </div>
</template>

<script>
import '../../../public/iconfont/iconfont.css';
import SearchLayoutItem from './search-layout-item';
export default {
  name: 'SearchLayout',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  mounted() {
    console.log(this);
  },
  components: {
    SearchLayoutItem,
  },
  data() {
    return {
      collapsed: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-layout {
  &-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0d1a26;
    padding: 5px 0 10px 0;
  }
  &-content {
    display: flex;
    flex-flow: row wrap;
    .btn-group {
      flex: 1 1 auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      ::v-deep.search-layout-item-value {
        text-align: right !important;
      }
      .btn-collapsed {
        outline: none;
        text-decoration: none;
        color: #1368e7 !important;
        background-color: transparent;
        cursor: pointer;
        transition: color 0.3s;
        margin-left: 8px;
        font-size: 14px;
        display: inline-block;
        i {
          color: #1368e7 !important;
          font-size: 14px;
          display: inline-block;
        }
        &.opened {
          i {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>