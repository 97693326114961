<template>
  <div class="checkbox" :class="{ checked: value }"   @click="handleOnCheckboxChange">
    <label class="checkmark"></label>
    <label class="checkbox-label">
      <slot>{{label}}</slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    // defaultChecked: Boolean,
    value: {
      type: Boolean,
    },
    label: String,
  },
  data() {
    return {
      // dChecked: false
    }
  },
  computed: {
    // isChecked() {
    //   if (this.checked == undefined) {
    //     return this.defaultChecked || this.dChecked
    //   } else {
    //     return this.checked
    //   }
    // }
  },

  mounted() {
  //  this.dChecked = this.checked
  },
  methods: {
    handleOnCheckboxChange() {    
      // this.dChecked = !this.dChecked
      this.$emit('input', !this.value)
      this.$emit('change', { label: this.label, checked: !this.value })
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  &.checked .checkmark {
    background-color: #1890ff;
  }
  &.checked .checkmark:before {
    opacity: 1;
  }
  label.checkmark {
    width: 16px;
    height: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      display: inline-block;
      content: ' ';
      width: 9px;
      height: 4px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      transform: translate(0, -1px) rotate(-45deg);
      opacity: 0;
      cursor: pointer;
    }
  }
  .checkbox-label {
    margin-left: 2px;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
  }
}
</style>