<template>
  <button class="btn" :class="{[type]: type}" @click="handleOnClick">
    <i class="iconfont" :class="{[icon]: icon}" v-if="icon && icon.length > 0"></i>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    icon: String,
    type: {
      type: String,
    }
  },
  methods: {
    handleOnClick(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
  button {
    outline: none;
    box-sizing: border-box;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #314659;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #CED4D9;
    padding: 0 16px;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 5px;
    }
    &:hover {
      color: #1368E7;
      border-color: #1368E7;
    }
    &:active {
      color: #1E62C9;
      border-color: #1E62C9;
    }
    &.primary {
      color: #FFFFFF;
      background: #1368E7;
      border-color: #1368E7;
      &:hover {
        background: rgba(19, 104, 231, 0.8);
      }
      &:active {
        background: #1E62C9;
      }
    }
    &.text {
      border: none;
    }
  }
</style>