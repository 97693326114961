<template>
  <div class="notification fade-in-left" v-if="value">
    <div class="notification-header">
      <div class="notification-header-left">
        <i aria-hidden="true" class="notification-header-left-icon iconfont" :class="'icon-' + type" v-if="type && type.length > 0"></i>
        <div class="notification-header-left-title">{{title}}</div>
      </div>
      <i aria-hidden="true" class="notification-header-close iconfont icon-close" @click="handleClose"></i>
    </div>
    <div class="notification-body" :class="{'has-icon': type && type.length > 0}">
      <div class="notification-body-message">{{message}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
      default: '',
    },
    message: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    duration: {
      type: Number,
      default: 2000,
    },
  },
  watch: {
    value(val) {
      this.$emit('input', val);
      if (this.duration == 0) return;
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.$emit('input', false);
      }, this.duration);
    },
  },
  methods: {
    handleClose() {
      this.$emit('input', false);
      this.$emit('close');
    },
  },
  beforeDestroy() {
    // console.log('destroy');
    clearTimeout(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.notification {
  position: fixed;
  z-index: 999999;
  right: 16px;
  top: 16px;
  width: 384px;
  min-height: 100px;
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px 24px 24px 24px;
  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC-Medium, PingFang SC;
    &-left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      &-icon {
        font-size: 22px;
        &.icon-success {
          color: #22dd7b;
        }
        &.icon-info {
          color: #1368e7;
        }
        &.icon-warning {
          color: #ff9425;
        }
        &.icon-error {
          color: #ff4d4f;
        }
      }
      &-title {
        margin-left: 10px;
        font-size: 16px;
        color: #0d1a26;
        font-weight: 500;
      }
    }
    &-close {
      color: #adb5bd;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #1368e7;
        font-size: 18px;
        transition: all 0.3s;
      }
    }
  }
  &-body {
    margin: 12px 24px 0 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #314659;
    line-height: 22px;
    &.has-icon {
      margin-left: 32px;
    }
  }
}
.fade-in-left {
  animation: fadeInLeft ease 200ms forwards;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>