<template>
  <div class="tab-pane">
    <slot v-if="$parent.active == value && isMounted"></slot>
  </div>
</template>

<script>
export default {
  name: 'TabPane',
  props: {
    value: String,
    title: String,
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMounted: true,
    }
  },
  mounted() {
    console.log('TabPane:', this)
    this.isMounted = !this.lazy
    this.$nextTick().then(() => {
      this.isMounted = true
    })
  },
  watch: {
    title() {
      // console.log('titleChange')
      this.$parent.$emit('change:title')
    }
  }
};
</script>

<style lang="scss" scoped>

</style>