export default {
  bind(el, binding, vnode) {
    el.listener = function (e) {
      if (e.target === el || el.contains(e.target)) return;
      vnode.context[binding.expression](e);
    }
    document.addEventListener('click', el.listener);
  },
  unbind(el, binding, vnode) {
    document.removeEventListener('click', el.listener);
    delete el.listener;
  }
}