<template>
  <div class="loader-wrapper">
    <div class="loader-mask" v-if="cOptions.hasMask && loading"></div>
    <div class="loader" v-if="loading" :style="`--speed: ${cOptions.speed}; --color: ${cOptions.color}; --size: ${cOptions.size}; --dot-size: ${cOptions.dotSize}; --dot-count: ${cOptions.dotCount}; --left: ${cOptions.left}; --top: ${cOptions.top};`">
      <span v-for="i in cOptions.dotCount" :key="i" :style="`--i: ${i};`"></span>
    </div>
  </div>
</template>

<script>
let defaultOptions = {
  left: '0px',
  top: '0px',
  color: '#bfa',
  size: '100px',
  dotSize: '15px',
  dotCount: 20,
  speed: 1,
  hasMask: false,
};
export default {
  name: 'Loader',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => defaultOptions,
    },
  },
  computed: {
    cOptions() {
      return {
        ...defaultOptions,
        ...this.options,
      };
    },
  }
};
</script>

<style scoped>
.loader-mask {
  position: absolute;
  z-index: 999;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .8);
}
.loader {
  position: absolute;
  z-index: 999;
  left: var(--left);
  right: var(--left);
  top: var(--top);
  bottom: var(--top);
  margin: auto;
  width: var(--size);
  height: var(--size);
}

.loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(360deg / var(--dot-count) * var(--i)));
}

.loader span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: var(--dot-size);
  height: var(--dot-size);
  background: var(--color);
  border-radius: 50%;
  /* box-shadow: 0 0 10px var(--color), 0 0 20px var(--color),
    0 0 40px var(--color), 0 0 80px var(--color), 0 0 100px var(--color); */
  animation: scale calc(var(--speed) * 1s) linear infinite;
  animation-delay: calc(var(--speed) * 1s / var(--dot-count) * var(--i));
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  80%,
  100% {
    transform: scale(0);
  }
}
</style>