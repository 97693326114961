<template>
  <div class="transfer">
    <div class="transfer-list">
      <div class="transfer-list-header">
        <div class="transfer-list-header-checkbox">
          <hv-checkbox @change="handleSourceCheckedAll" :checked="sourceCheckedAll"></hv-checkbox>
          <span class="transfer-list-header-checkbox-count">{{sourceCount}}</span>
        </div>
        <div class="transfer-list-header-title">{{titles[0]}}</div>
      </div>
      <div class="transfer-list-content">
        <div class="transfer-list-content-item" v-for="item in cSourceData" :key="item.key">
          <hv-checkbox :value="item" :checked="!!checkedSourceData.find(ele => ele.key == item.key)" @change="handleSourceItemChecked">
            <span class="item-label">{{item.label}}</span>
          </hv-checkbox>
        </div>
      </div>
    </div>
    <div class="transfer-actions">
      <div class="action action-right" :class="{disabled: checkedSourceData.length == 0}" @click="moveToTarget">
        <i class="iconfont icon-right"></i>
      </div>
      <div class="action action-left" :class="{disabled: checkedTargetData.length == 0}" @click="moveToSource">
        <i class="iconfont icon-left"></i>
      </div>
    </div>
    <div class="transfer-list">
      <div class="transfer-list-header">
        <div class="transfer-list-header-checkbox">
          <hv-checkbox @change="handleTargetCheckedAll" :checked="targetCheckedAll"></hv-checkbox>
          <span class="transfer-list-header-checkbox-count">{{targetCount}}</span>
        </div>
        <div class="transfer-list-header-title">{{titles[1]}}</div>
      </div>
      <div class="transfer-list-content">
        <template v-if="targetData.length > 0">
          <div class="transfer-list-content-item" v-for="item in targetData" :key="item.key">
            <hv-checkbox :value="item" :checked="!!checkedTargetData.find(ele => ele.key == item.key)" @change="handleTargetItemChecked">
              <span class="item-label">{{item.label}}</span>
            </hv-checkbox>
          </div>
        </template>
        <template v-else>
          <div class="transfer-list-content-no-data">
            <i class="iconfont icon-no-data"></i>
            <div class="no-data-text">暂无数据</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transfer',
  props: {
    titles: {
      type: Array,
      default: () => ['表一', '表二'],
    },
    sourceData: {
      type: Array,
      default: () => [],
    },
    targetKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedSourceData: [],
      checkedTargetData: [],
      targetData: [],
      dTargetKeys: [],
    };
  },
  mounted() {
    this.targetData = this.sourceData.filter((item) =>
      this.targetKeys.includes(item.key)
    );
  },
  computed: {
    // cSourceData() {
    //   return this.sourceData.filter(
    //     (item) => !this.dTargetKeys.includes(item.key)
    //   );
    // },
    cSourceData() {
      return this.sourceData.filter(
        (sourceItem) =>
          !this.targetData.find(
            (targetItem) => targetItem.key == sourceItem.key
          )
      );
    },
    sourceCheckedAll() {
      return (
        this.checkedSourceData.length == this.cSourceData.length &&
        this.cSourceData.length != 0
      );
    },
    targetCheckedAll() {
      return (
        this.checkedTargetData.length == this.targetData.length &&
        this.targetData.length != 0
      );
    },
    sourceCount() {
      let checkedCount = this.checkedSourceData.length;
      let totalCount = this.cSourceData.length;
      return checkedCount > 0
        ? `${checkedCount}/${totalCount}项`
        : `${totalCount}项`;
    },
    targetCount() {
      let checkedCount = this.checkedTargetData.length;
      let totalCount = this.targetData.length;
      return checkedCount > 0
        ? `${checkedCount}/${totalCount}项`
        : `${totalCount}项`;
    },
  },
  methods: {
    handleSourceCheckedAll(data) {
      console.log('handleSourceCheckedAll:', data);
      if (data.checked) {
        this.checkedSourceData = this.cSourceData;
        console.log(this.checkedSourceData);
      } else {
        this.checkedSourceData = [];
      }
    },
    handleSourceItemChecked(data) {
      // console.log(data)
      if (data.checked) {
        this.checkedSourceData.push(data.value);
      } else {
        this.checkedSourceData = this.checkedSourceData.filter(
          (item) => item.key != data.value.key
        );
      }
    },
    handleTargetCheckedAll(data) {
      console.log('handleTargetCheckedAll:', data);
      if (data.checked) {
        this.checkedTargetData = this.targetData;
      } else {
        this.checkedTargetData = [];
      }
    },
    handleTargetItemChecked(data) {
      console.log('handleTargetItemChecked:', data);
      if (data.checked) {
        this.checkedTargetData.push(data.value);
      } else {
        this.checkedTargetData = this.checkedTargetData.filter(
          (item) => item.key != data.value.key
        );
      }
    },
    moveToTarget() {
      this.targetData = [...this.targetData, ...this.checkedSourceData];
      this.checkedSourceData = [];
      this.$emit('change', this.targetData);
    },
    moveToSource() {
      this.targetData = this.targetData.filter(
        (item) =>
          !this.checkedTargetData.find(
            (checkedItem) => checkedItem.key == item.key
          )
      );
      this.checkedTargetData = [];
      this.$emit('change', this.targetData);
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer {
  // width: 600px;
  height: 300px;
  max-height: 300px;
  margin: 20px;
  line-height: 1.5;
  vertical-align: middle;
  &-list {
    display: inline-block;
    box-sizing: border-box;
    min-width: 180px;
    min-width: 180px;
    max-width: 300px;
    height: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    vertical-align: middle;
    &-header {
      box-sizing: border-box;
      height: 40px;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.65);
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 4px 4px 0 0;
      padding: 0 10px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      &-checkbox {
        line-height: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        &-count {
          padding: 0 5px;
        }
      }
      &-title {
        font-size: 15px;
        font-weight: 400;
      }
    }
    &-content {
      box-sizing: border-box;
      height: calc(100% - 40px);
      padding: 10px;
      overflow-y: auto;
      &-item {
        padding-bottom: 5px;
        .item-label {
          font-size: 14px;
          font-weight: normal;
          margin-left: 5px;
        }
      }
      &-no-data {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        color: #eee;
        i {
          color: #eee;
          font-size: 42px;
        }
        .no-data-text {
          font-size: 18px;
          padding-bottom: 30px;
        }
      }
    }
  }
  &-actions {
    display: inline-block;
    margin: 0 8px;
    vertical-align: middle;
    line-height: 20px;
    .action {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      margin: 4px 0;
      cursor: pointer;
      color: #fff;
      background-color: #1368e7;
      border: 1px solid #1368e7;
      // text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
      // box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
      &:not(&.disabled):hover {
        color: #fff;
        background-color: #1890ff;
        border-color: #1890ff;
      }
      &.disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border: 1px solid #d9d9d9;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
</style>