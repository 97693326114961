<template>
  <div class="message fade-in-down" v-if="value">
    <div class="message-inner">
      <i aria-hidden="true" class="message-inner-icon iconfont" :class="'icon-' + type"></i>
      <div class="message-inner-title">{{message}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    type: {
      type: String,
      default: 'info',
    },
    message: {
      type: String,
    },
    duration: {
      type: Number,
      default: 2000,
    },
    value: {
      type: Boolean,
    },
  },
  mounted() {
    // this.timer = setTimeout(() => {
    //   this.$emit('input', false);
    // }, this.duration);
  },
  beforeDestroy() {
    // console.log('destroy');
    clearTimeout(this.timer);
    this.timer = null;
  },
  watch: {
    value(val) {
      // console.log(val, '***')
      clearTimeout(this.timer)
      this.timer = null
      this.$emit('input', val);
      this.timer = setTimeout(() => {
        this.$emit('input', false);
      }, this.duration);
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  top: 24px;
  left: 0;
  right: 0;
  text-align: center;
  &-inner {
    margin: 0 auto;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 16px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: all;
    &-icon {
      font-size: 16px;
      &.icon-success {
        color: #22dd7b;
      }
      &.icon-info {
        color: #1368e7;
      }
      &.icon-warning {
        color: #ff9425;
      }
      &.icon-error {
        color: #ff4d4f;
      }
    }
    &-title {
      font-weight: 400;
      color: #314659;
      margin-left: 4px;
      font-size: 14px;
    }
  }
}
.fade-in-down {
  animation: fadeInDown ease 200ms forwards;
}
.fade-out-up {
  animation: fadeOutUp ease 200ms forwards;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
</style>