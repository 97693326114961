<template>
  <div class="upload">
    <div class="upload-header">
      <div class="upload-header-title">{{title}}</div>
      <div class="upload-header-actions">
        <i class="iconfont btn-layout" :class="{ 'icon-layout-list': layout == 'list', 'icon-layout-grid': layout == 'grid' }" @click="changeLayout"></i>
      </div>
    </div>
    <VueFileAgent :theme="layout" v-model="modelValue" v-bind="$props" v-on="$listeners"></VueFileAgent>
    <!-- <button :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">
      Upload {{ fileRecordsForUpload.length }} files
    </button> -->
  </div>
</template>

<script>
import Vue from 'vue';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
Vue.use(VueFileAgent)
export default {
  name: 'Upload',
  props: {
    // accept: {
    //   type: String,
    //   default: ''
    // },
    // deletable: Boolean,
    // editable: Boolean,
    // errorText: Object, // { type: , size: }
    // helpText: {
    //   type: String,
    //   default: '点击或拖拽上传'
    // },
    // linkable: Boolean,
    // maxFiles: Number,
    // maxSize: String, // 10MB
    // meta: {
    //   type: Boolean,
    //   default: true
    // },
    // multiple: {
    //   type: Boolean | String,
    //   default: 'auto'
    // },
    // sortable: { //  (true - drag to sort, 'hold' - hold and drag to sort, 'handle' - drag the handle to sort), default false
    //   type: Boolean | String,
    //   default: false
    // },
    //  theme: {
    //   type: String,
    //   default: 'list'
    // },
    // uploadConfig: Function,
    // uploadUrl: String,
    // value: Object | Array
    defaultLayout: {
      type: String,
      default: 'grid'
    },
    title: String,
    ...VueFileAgent.VueFileAgent.options.props,
    helpText: {
      type: String,
      default: '请选择或拖拽文件到内容区上传'
    }
  },
  mixins: [...VueFileAgent.VueFileAgent.options.mixins],
  mounted() {
    this.layout = this.defaultLayout
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  data() {
    return {
      layout: 'grid',
      ...VueFileAgent.VueFileAgent.options.data
    };
  },
  methods: {
    changeLayout() {
      if (this.layout == 'grid') {
        this.layout = 'list'
      } else {
        this.layout = 'grid'
      }
    },
   ...VueFileAgent.VueFileAgent.options.methods,
  },
};
</script>

<style lang="scss" scoped>
::v-deep.upload {
  &-header {
    height: 36px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    &-title {
      font-weight: 600;
      color: #0d1a26;
      font-size: 16px;
      display: inline-block;
    } 
    &-actions {
      margin-left: auto;
      height: 100%;
      display: inline-flex;
      align-items: center;
      .btn-layout {
        font-size: 19px;
        font-weight: 400;
        cursor: pointer;
        display: inline-block;
        &:hover {
          color: rgba(19, 104, 231, 0.8);
        }
      }
    }
  }
  .vue-file-agent {
    // border: none;
    &.file-input-wrapper {
      text-align: left;
    }
  } 
}
</style>
