<template>
  <div class="table-layout">
    <div class="table-layout-header">
      <div class="title">{{ title }}</div>
      <div class="actions">
        <slot name="headerBar"></slot>
        <div
          class="action action-column-filter"
          @click="showColumnFilter = true"
          v-click-outside="handleClickOutside"
          v-if="columnFilter"
        >
          <!-- <i class="iconfont icon-setting action-column-filter-icon"></i> -->
          <span class="action-column-filter-title">列设置</span>
          <div class="action-column-filter-drop" v-if="showColumnFilter">
            <div class="drop-header drop-item">
              <input
                id="column-all"
                type="checkbox"
                :checked="filterColumnsCheckedAll"
                @change="handleColumnFilterCheckedAll"
              />
              <label class="checkmark" for="column-all"></label>
              <label class="title checkbox-label" for="column-all"
                >列展示</label
              >
            </div>
            <div class="drop-content">
              <div
                class="drop-item"
                v-for="(item, idx) in filterColumns"
                :key="idx"
              >
                <input
                  :id="item.id + '-checkbox'"
                  type="checkbox"
                  @change="handleColumnFilter(item)"
                  :checked="!item.hidden"
                />
                <label class="checkmark" :for="item.id + '-checkbox'"></label>
                <label class="checkbox-label" :for="item.id + '-checkbox'">{{
                  item.label || item.title || item.name
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
    <div class="table-layout-footer" v-if="footer">
      <slot name="footerBar"></slot>
      <div class="table-layout-footer-desc">
        <slot name="footerDesc"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutside from "@/directives/click-outside";
export default {
  name: "TableLayout",
  props: {
    columnFilter: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "查询结果",
    },
    footer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showColumnFilter: false,
      filterColumns: [],
    };
  },
  computed: {
    filterColumnsCheckedAll() {
      return this.filterColumns.every((item) => !item.hidden);
    },
  },
  mounted() {
    console.log(this);
    this.table = this.$children.find(
      (item) => item.columns && Array.isArray(item.columns)
    );
    this.initTableHeader();
    this.initTableStyle();
    window.addEventListener("resize", () => {
      this.initTableStyle();
    });
  },
  methods: {
    initTableHeader() {
      // this.filterColumns = this.table.columns.filter(
      //   (item) => item.name != 'checked' && item.name != 'id'
      // );
      this.filterColumns = this.table.columns || [];
    },
    initTableStyle() {
      setTimeout(() => {
        let tableWrapper = document.querySelector(
          ".table-layout .el-table__body-wrapper"
        );
        if (tableWrapper.scrollWidth <= tableWrapper.clientWidth) {
          tableWrapper.classList.add("is-scrolling-none");
        } else {
          tableWrapper.classList.remove("is-scrolling-none");
        }
        tableWrapper.addEventListener("scroll", (e) => {
          let { scrollLeft, scrollWidth, clientWidth } = e.target;
          if (scrollWidth <= clientWidth) {
            e.target.classList.add("is-scrolling-none");
          } else {
            e.target.classList.remove("is-scrolling-none");
            if (scrollLeft == 0) {
              e.target.classList.remove("is-scrolling-right");
              e.target.classList.add("is-scrolling-left");
            } else if (scrollLeft == scrollWidth - clientWidth) {
              e.target.classList.remove("is-scrolling-left");
              e.target.classList.add("is-scrolling-right");
            } else {
              e.target.classList.remove("is-scrolling-right");
              e.target.classList.remove("is-scrolling-left");
            }
          }
        });
      }, 1000);
    },
    handleClickOutside() {
      this.showColumnFilter = false;
    },
    handleColumnFilterCheckedAll(e) {
      let { checked } = e.target;
      if (checked) {
        this.filterColumns = this.filterColumns.map((item) => {
          item.hidden = false;
          return item;
        });
      } else {
        this.filterColumns = this.filterColumns.map((item) => {
          item.hidden = true;
          return item;
        });
      }
      this.initTableStyle();
    },
    handleColumnFilter(item) {
      item.hidden = !item.hidden;
      if (item.fixed) {
        let columnEl = document.querySelector(".el-table__fixed-" + item.fixed);
        if (item.hidden) {
          columnEl.style.display = "none";
        } else {
          columnEl.style.display = "block";
        }
      }
      this.initTableStyle();
    },
  },
  directives: {
    clickOutside,
  },
};
</script>

<style lang="scss" scoped>
.table-layout {
  padding: 0 24px 0 24px;
  &-header {
    height: 56px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 600;
      color: #0d1a26;
      font-size: 16px;
    }
    .actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .action {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: #314659;
        cursor: pointer;
        position: relative;
        i {
          margin: 0 2px;
          color: #314659;
        }
      }
      .action-column-filter {
        border-radius: 2px;
        border: 1px solid #ced4d9;
        padding: 6px 15px;
        &:hover {
          background: #ffffff;
          border: 1px solid rgba(19, 104, 231, 0.8);
          .action-column-filter-title {
            color: rgba(19, 104, 231, 0.8);
          }
          .action-column-filter-icon {
            color: rgba(19, 104, 231, 0.8);
          }
        }
        &-drop {
          width: 180px;
          background: #fff;
          position: absolute;
          right: 0;
          top: calc(100% + 8px);
          z-index: 999;
          box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%),
            0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
          &::before {
            position: absolute;
            top: -4.24px;
            right: 20px;
            content: "";
            display: block;
            border: 6px solid;
            border-color: #fff transparent transparent #fff;
            box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
            transform: rotate(45deg);
          }
          .drop-item {
            &:not(.drop-header):hover {
              background-color: #268bff14;
            }
            box-sizing: border-box;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 3px 16px 3px 16px;
            cursor: pointer;
            input[type="checkbox"] {
              display: none;
            }
            input:checked ~ .checkmark {
              background-color: #1368e7;
            }
            input:checked ~ .checkmark:before {
              opacity: 1;
            }
            label.checkmark {
              display: inline-block;
              width: 16px;
              height: 16px;
              border: 1px solid #d9d9d9;
              border-radius: 2px;
              border-collapse: separate;
              transition: all 0.3s;
              margin-right: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              &::before {
                display: inline-block;
                content: " ";
                position: absolute;
                width: 9px;
                height: 4px;
                border: 2px solid #fff;
                border-top: none;
                border-right: none;
                transform: translate(0, -1px) rotate(-45deg);
                opacity: 0;
                cursor: pointer;
              }
            }
            .checkbox-label {
              margin-left: 6px;
              cursor: pointer;
            }
          }
          .drop-header {
            padding: 8px 16px;
            border-bottom: 1px solid #f0f0f0;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
          }
          .drop-content {
            padding: 6px 0;
          }
        }
      }
    }
  }
  &-footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px -6px 20px 0px rgba(0, 0, 0, 0.05);
    z-index: 999;
    padding: 0 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    &-desc {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0d1a26;
    }
  }
}
</style>
