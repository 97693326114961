<template>
  <div class="tabs">
    <div class="tabs-bar">
      <div
        class="tabs-bar-item"
        :class="{ active: active == item.value }"
        v-for="(item, index) in tabs"
        :key="index"
        @click="handleTabsChange(item)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="tabs-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    default: {
      type: String | Number,
      default: 0,
    },
  },
  // model: {
  //   prop: 'active',
  //   event: 'changeActive'
  // },
  data() {
    return {
      tabs: [],
      active: "",
    };
  },
  mounted() {
    console.log("Tabs:", this);
    this.active = this.default;
    this.tabs = this.$children.map((item) => {
      return {
        title: item.title,
        value: item.value,
      };
    });
    this.$on('change:title', () => {
      console.log('aaaaa')
      this.tabs = this.$children.map((item) => {
        return {
          title: item.title,
          value: item.value,
        };
      });
    });
  },
  methods: {
    handleTabsChange(item) {
      this.$emit("change", { title: item.title, value: item.value });
      this.active = item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  &-bar {
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #eee;
    &-item {
      box-sizing: border-box;
      margin: 0 20px;
      padding: 0 10px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 100%;
      &:hover {
        color: rgba(19, 104, 231, 0.8);
      }
      &.active {
        color: #1368e7;
        border-bottom: 2px solid #1368e7;
      }
    }
  }
}
</style>
