<template>
  <div class="group">
    <div class="group-title">{{title}}</div>
    <div class="group-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Group',
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.group {
   &-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0d1a26;
    padding: 5px 0 10px 0;
  }
  &-content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}
</style>